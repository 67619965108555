/* General Styles for NavBar */
.l-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: all 0.3s ease;
}

.bd-container_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1450px;
  margin: 0 auto;
  padding: 20px;
}

/* Centering nav items for desktop view */
.nav__menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.nav__list {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding-left: 0;
}

.nav__item {
  margin: 0 15px;
}

.nav__link {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}

.nav__link:hover {
  color: grey;
}

/* Button centered */
.nav__item .ant-btn {
  margin: 0 auto;
  display: block;
  color: white;
  border-color: white;
}

.theme-switcher-container {
  margin-left: 15px;
}

/* Color change on scroll */
.l-header.scrolled .nav__link {
  color: grey;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .nav__menu {
    display: block;
    background-color: rgba(0, 0, 0, 0.7); /* Transparent black background for mobile */

  }

  .nav__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .theme-switcher-container {
    margin: 0;
  }

  .nav__item .ant-btn {
    text-align: center;
    width: 100%;
  }
}
