.faq-container {
  width: 80%;
  margin: 0 auto;
  background-color: #f5f5f5;
  padding: 40px;
}
.faq-container h1 {
  margin: 0;
  font-size: 1.5rem;
  color: #d12336;
  text-align: center;
}
.faq-container h2 {
  margin: 0;
  color: black;
  
  font-size: 2rem;
  text-align: center;
}
.faq-title {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  color: #333;
}

.faq-panel {


  background-color: #ffffff !important;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  font-family: 'Open Sans', sans-serif;
  transition: all 0.3s ease;
}

.faq-panel:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.faq-icon {
  font-size: 18px;
  color: #1890ff; /* Ant Design primary color for active state */
}

.ant-collapse-header {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.ant-collapse-content {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
}

.ant-collapse-content-box {
  padding-left: 24px;
}

@media (max-width: 768px) {
  .faq-container {
    width: 100%;
    padding: 20px;
  }

  .faq-title {
    font-size: 24px;
  }

  .faq-panel {
    font-size: 16px;
  }
}
