.about-section {
  padding: 50px 0;
  background-color: #fff;
}

.about-content {
  display: flex;
  align-items: center;
  width: 98%;
}

.about-gif {
  display: flex;
  justify-content: center; /* Centers the GIF horizontally */
  align-items: center; /* Centers the GIF vertically */
  height: 100%; /* Makes sure the GIF takes up the full height of the column */
}

.gif-container img {
  max-width: 85%;
  height: auto;
  margin-left: 10%;
  border-radius: 8px;
}

.about-text {
  padding: 20px;
}



.about-subtitle {
  color: #d12336;
  font-size: 23px;
  font-weight: bold;
}

.about-title {
  font-size: 40px;
  font-weight: 700;
  color: #000;

  text-align: left; /* Left-align for larger screens */
  /* margin-bottom: 20px; */
}

.about-description {
  font-size: 16px;
  line-height: 1.5;
  color: black;
}
/* Center text for mobile view */
@media (max-width: 767px) {
  .about-content {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: -20px;
  }
  .about-text {
    text-align: left; /* Center text */
    padding: 10px;
    margin-left: 5%;

  }

  .about-title {
    font-size: 30px;
    margin-top: -10px;

    font-weight: 700;
    color: #000;
    text-align: left; /* Center text */
    /* margin-bottom: 20px; */
  }

  .about-subtitle {
    color: #d12336;
    font-size: 23px;
    font-weight: bold;
    margin-top: -10px;
    text-align: left; 
  }
}